var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "set-delivery-position"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "设置定位",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "set-location-wrapper app-main-container"
  }, [_c('van-button', {
    staticClass: "update-btn",
    attrs: {
      "type": "info",
      "icon": "replay"
    },
    on: {
      "click": _vm.updateLocation
    }
  }, [_vm._v("重新定位")]), _c('div', {
    staticClass: "current-location-wrap"
  }, [_c('div', {
    staticClass: "current-location"
  }, [_c('van-icon', {
    attrs: {
      "name": "location-o",
      "size": "20px"
    }
  }), _vm._v(" 当前定位： ")], 1), _c('div', {
    staticClass: "detail-address"
  }, [_vm._v(" " + _vm._s(_vm.detailAddress) + " ")])]), _c('van-cell', {
    attrs: {
      "title": "查看地图",
      "is-link": ""
    },
    on: {
      "click": _vm.lookupLocation
    }
  })], 1), _c('div', {
    staticClass: "fixed-bottom-btn",
    on: {
      "click": _vm.submitLocation
    }
  }, [_c('van-button', [_vm._v("提交定位")])], 1), _vm.showMap ? _c('el-amap', {
    staticClass: "el-map-css",
    attrs: {
      "amap-manager": _vm.amapManager,
      "vid": 'amap-vue',
      "zoom": 18,
      "expand-zoom-range": true,
      "center": [_vm.saveTude.longitude, _vm.saveTude.latitude]
    }
  }, [_c('el-amap-marker', {
    attrs: {
      "position": [_vm.saveTude.longitude, _vm.saveTude.latitude]
    }
  })], 1) : _vm._e(), _vm.showMap ? _c('van-button', {
    staticClass: "location-map-close-btn",
    attrs: {
      "type": "info",
      "icon": "arrow-left"
    },
    on: {
      "click": function click($event) {
        _vm.showMap = false;
      }
    }
  }, [_vm._v("返回")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }