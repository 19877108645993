<template>
  <div class="set-delivery-position">
    <my-nav-bar title="设置定位" left-text="返回" left-arrow />
    <div class="set-location-wrapper app-main-container">
      <van-button type="info" icon="replay" class="update-btn" @click="updateLocation">重新定位</van-button>
      <div class="current-location-wrap">
        <div class="current-location"><van-icon name="location-o" size="20px" />
          当前定位：
        </div>
        <div class="detail-address">
          {{ detailAddress }}
        </div>
      </div>
      <van-cell title="查看地图" is-link @click="lookupLocation" />

    </div>
    <div class="fixed-bottom-btn" @click="submitLocation">
      <van-button>提交定位</van-button>
    </div>
    <el-amap
      v-if="showMap"
      :amap-manager="amapManager"
      :vid="'amap-vue'"
      :zoom="18"
      :expand-zoom-range="true"
      :center="[saveTude.longitude, saveTude.latitude]"
      class="el-map-css"
    >
      <el-amap-marker :position="[saveTude.longitude, saveTude.latitude]" />
    </el-amap>
    <van-button v-if="showMap" type="info" icon="arrow-left" class="location-map-close-btn" @click="showMap = false">返回</van-button>
  </div>
</template>
<script>
import MyNavBar from '@/components/my-nav-bar'
import wx from 'weixin-js-sdk'
import deliveryOrderApi from '@/api/delivery_order'
import { getSDKConfig } from '@/api/wechat'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'

export default {
  name: 'SetPosition',
  components: {
    MyNavBar
  },
  data() {
    return {
      sdkConfig: {},
      locationMsg: {},
      saveTude: { longitude: 121.5273285, latitude: 31.21515044 }, // 保存经纬度
      detailAddress: '',
      showMap: false,
      amapManager: new AMapManager()
    }
  },
  created() {
    // 获取访问的user-agent
    var ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
    // 判断user-agent
    var isWX = /MicroMessenger/i.test(ua)
    // if (!isWX) {
    //   this.fail('请使用微信打开')
    //   return
    // }
    if (isWX) {
      var isIOS = /(iPhone|iPad|iPod|iOS)/i.test(ua) // 苹果家族
      var isAndroid = /(android|nexus)/i.test(ua) // 安卓家族
      const split_path = location.href.split('/#/')[1]
      let path
      if (split_path.indexOf('?') === -1) {
        path = split_path
      } else {
        path = split_path.split('?')[0]
      }
      let params
      if (isIOS) {
        params = { platform: 'iOS', path }
      } else if (isAndroid) {
        params = { platform: 'Android', path }
      }
      this.beginLoad()
      getSDKConfig(params).then(res => {
        this.sdkConfig = res.data
        this.endLoad()
        const { appId, debug, jsApiList, nonceStr, signature, timestamp } = this.sdkConfig
        wx.config({
          debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList // 必填，需要使用的JS接口列表
        })
      })
      // wx.ready(() => { // wx.config成功后回调该函数
      //   this.getCurrentLocation()
      // })
    }
  },
  mounted() {
    this.getCurrentLocation()
  },
  methods: {
    getCurrentLocation() {
      lazyAMapApiLoaderInstance.load().then(() => {
        AMap.plugin('AMap.Geolocation', res => {
          var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 20000,
            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            buttonOffset: new AMap.Pixel(10, 20),
            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            zoomToAccuracy: true,
            panToLocation: true,
            showButton: true,
            //  定位按钮的排放位置,  RB表示右下
            buttonPosition: 'RB'
          })

          // geolocation.getCurrentPosition()
          // AMap.event.addListener(geolocation, 'complete', onComplete)
          // AMap.event.addListener(geolocation, 'error', onError)
          geolocation.getCurrentPosition((status, result) => {
            if (status === 'complete') {
              onComplete(result)
            } else {
              onError(result)
            }
          })

          const onComplete = (data) => {
            // data是具体的定位信息 position是经纬度 formattedAddress当前地址信息
            const { lng, lat } = data.position
            const params = {
              latitude: lat,
              longitude: lng
            }
            this.saveTude = params
            this.beginLoad()
            deliveryOrderApi.getCurrentLocation(params).then(res => {
              this.locationMsg = res.data
              const { province, city, district, address } = this.locationMsg
              this.detailAddress = province + city + district + address
              this.endLoad()
            })
          }
          const onError = (data) => {
            // 定位出错
            this.fail(data.message)
          }
        })
      })
    },
    // getCurrentLocation() { // 进入界面wx.config配置完 和 点击更新当前定位调用
    //   wx.getLocation({
    //     type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    //     success: res => {
    //       var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
    //       var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
    //       alert(latitude, longitude)
    //       const params = {
    //         latitude,
    //         longitude
    //       }
    //       this.saveTude = {
    //         latitude,
    //         longitude
    //       }
    //       this.beginLoad()
    //       deliveryOrderApi.getCurrentLocation(params).then(res => {
    //         this.locationMsg = res.data
    //         const { province, city, district, address } = this.locationMsg
    //         this.detailAddress = province + city + district + address
    //         this.endLoad()
    //       })
    //     },
    //     fail: () => {
    //       this.fail('获取定位失败，请打开定位，重新进入！')
    //     }
    //   })
    // },
    updateLocation() { // 点击更新定位
      this.getCurrentLocation()
    },
    lookupLocation() { // 点击查看定位
      var ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
      // 判断user-agent
      var isWX = /MicroMessenger/i.test(ua)
      if (isWX) {
        // wx.getLocation({ //
      //   type: 'gcj02', // 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于 wx.openLocation 的坐标
      //   success: res => {
        wx.openLocation({
          latitude: this.saveTude.latitude, // 纬度，范围为-90~90，负数表示南纬
          longitude: this.saveTude.longitude, // 经度，范围为-180~180，负数表示西经
          scale: 15, // 缩放比例
          name: this.$route.query.client_name,
          address: this.detailAddress,
          success: function(r) {

          }
        })
      } else {
        this.showMap = true
      }

      // }
      // })
    },
    submitLocation() { // 点击提交定位
      const { district_code, city_code, address } = this.locationMsg
      const { longitude, latitude } = this.saveTude
      const area_code = district_code || city_code
      const data = {
        client_id: this.$route.query.client_id,
        area_code,
        address,
        longitude,
        latitude
      }
      this.confirm('确定要提交定位吗?').then(() => {
        this.beginLoad()
        deliveryOrderApi.changeCustomerLocation(data).then(res => {
          this.endLoad()
          this.success(res.msg)
          this.$router.go(-1)
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
   .location-map-close-btn{
     height: 30px;
     position: fixed;
     left: 25px;
     top: 25px;
     z-index: 9999;
     padding: 0 10px;
     opacity: .8;
   }
 .set-location-wrapper{
   margin-left:10px;
   margin-right:10px;
   /* background-color:#fff; */
   .update-btn{
     margin:10px 0;
     height:40px;
     &.van-button--normal{
       padding:0 5px;
     }
   }
   .current-location-wrap{
     display: flex;
     align-items: center;
     margin:0 0 2px;
     padding: 10px;
     font-size:16px;
     background-color: white;
   }
   .current-location{
     width:105px;
     display:flex;
     align-items:center;
     flex-wrap: nowrap;
     flex-shrink: 0;
   }
   .detail-address{
     color: gray;
   }
   .van-cell{
     .van-cell__title{
       font-size: 16px;
     }
   }
 }
 .fixed-bottom-btn{
   .van-button{
     width:100%;
     font-size: 16px;
   }
 }
 .el-map-css{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
</style>
